import '../styles/Table.css'
import SideBar from './SideBar'

export default function Table() {


    return (
        <>

        </>
    )
}